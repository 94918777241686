import { computed, ref, unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';

export type TableOptions = {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
};

export default function useTableOptions(
  options?: MaybeRef<TableOptions>
) {
  const {
    page = 1,
    itemsPerPage = 10,
    sortBy = [],
    sortDesc = [],
    groupBy = [],
    groupDesc = [],
    multiSort = true,
    mustSort = false
  } = unref(options) ?? {} as any;

  const optionsRef = ref({
    page,
    itemsPerPage,
    sortBy,
    sortDesc,
    groupBy,
    groupDesc,
    multiSort,
    mustSort
  });

  return unref(optionsRef);
}

export { useTableOptions };
